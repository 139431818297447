import React, { useState, useEffect } from 'react';
import './App.css';
import { auth, db } from './firebaseConfig';
import { onAuthStateChanged, signInWithPopup, signOut, GoogleAuthProvider } from 'firebase/auth';
import { addDoc, collection } from 'firebase/firestore';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { FaBars, FaShoppingCart, FaClipboardList, FaSignOutAlt, FaTimes, FaSignInAlt, FaPlus } from 'react-icons/fa';
import ShoppingList from './components/ShoppingList';
import PurchasedList from './components/PurchasedList';

const NavLink = ({ to, children, icon, className, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const activeClassName = isActive ? 'bg-145B5F text-white' : 'hover:bg-70AF97 text-gray-700';

  return (
    <Link to={to} className={`${className} ${activeClassName} flex items-center px-4 py-2 rounded`} onClick={onClick}>
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </Link>
  );
};

const Navbar = ({ handleSignOut, user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className="relative w-full flex justify-between items-center navbar fixed top-0 left-0 bg-white shadow p-4 z-50">
      <span className="font-bold italic">MemoApp</span>
      <button onClick={toggleMenu}>
        <FaBars size={24} />
      </button>
      {isOpen && (
        <>
          <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={toggleMenu}></div>
          <div className="fixed top-0 right-0 w-3/4 h-full bg-white z-50 p-4">
            <div className="absolute top-4 left-4">
              <button onClick={toggleMenu} className="text-blue-500">
                <FaTimes size={24} />
              </button>
            </div>
            <div className="mt-8 pt-8 space-y-4">
              <NavLink to="/" icon={<FaShoppingCart />} className="block menu-item" onClick={toggleMenu}>Memo</NavLink>
              <NavLink to="/purchased" icon={<FaClipboardList />} className="block menu-item" onClick={toggleMenu}>Checked</NavLink>
              <button
                onClick={() => {
                  handleSignOut();
                  toggleMenu();
                }}
                className="block w-full text-left px-4 py-2 text-blue-500 hover:bg-145B5F flex items-center"
              >
                <FaSignOutAlt className="mr-2" /> {user.displayName}：ログアウト
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function App() {
  const [user, setUser] = useState(null);
  const [items, setItems] = useState([]);
  const [purchasedItems, setPurchasedItems] = useState([]);
  const [input, setInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is signed in: ", user);
        setUser(user);
      } else {
        console.log("No user is signed in.");
      }
    });
  }, []);

  const handleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("User signed in: ", result.user);
        setUser(result.user);
      })
      .catch((error) => {
        console.error("Error signing in: ", error);
      });
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out.");
        setUser(null);
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleAddItem = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const newItem = {
      text: input,
      purchased: false,
      createdAt: new Date(),
      order: items.length > 0 ? items[0].order - 1 : 0,
    };

    try {
      await addDoc(collection(db, `users/${user.uid}/items`), newItem);
      setInput('');
    } catch (error) {
      console.error("Error adding item: ", error);
    }
  };

  return (
    <Router>
      <div className="min-h-screen flex flex-col items-center pt-12">
        {user ? (
          <>
            <Navbar handleSignOut={handleSignOut} user={user} />
            <div className="w-full max-w-screen-lg px-2 mt-4">
              <Routes>
                <Route exact path="/" element={
                  <ShoppingList
                    items={items}
                    setItems={setItems}
                    purchasedItems={purchasedItems}
                    setPurchasedItems={setPurchasedItems}
                    input={input}
                    handleInputChange={handleInputChange}
                    handleAddItem={handleAddItem}
                  />
                } />
                <Route path="/purchased" element={<PurchasedList purchasedItems={purchasedItems} setPurchasedItems={setPurchasedItems} />} />
              </Routes>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center mt-20">
            <h1 className="text-3xl mb-6">ようこそ！</h1>
            <p className="text-lg mb-4">ご利用にはログインが必要です。</p>
            <button
              onClick={handleSignIn}
              className="bg-blue-500 text-white px-6 py-3 rounded flex items-center"
            >
              <FaSignInAlt className="mr-2" />
              Sign in with Google
            </button>
          </div>
        )}
        <div className="fixed bottom-0 left-0 w-full bg-white p-4 form-container">
          <form onSubmit={handleAddItem} className="flex w-full max-w-screen-lg mx-auto">
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              className="border p-2 mr-2 form-input"
              placeholder="メモを入力"
              style={{ width: '100%' }}
            />
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded flex items-center">
              <FaPlus />
            </button>
          </form>
        </div>
      </div>
    </Router>
  );
}

export default App;

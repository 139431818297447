import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig';

const PurchasedList = ({ purchasedItems, setPurchasedItems }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    if (!user) return;

    const q = query(collection(db, `users/${user.uid}/items`), orderBy('purchasedDate', 'desc'));
    const unsub = onSnapshot(q, (snapshot) => {
      const itemsData = [];
      snapshot.forEach((doc) => itemsData.push({ ...doc.data(), id: doc.id }));
      setPurchasedItems(itemsData.filter((item) => item.purchased));
    });

    return () => unsub();
  }, [user, setPurchasedItems]);

  // 日付ごとにアイテムをグループ化し、新しい日付が先に来るようにソート
  const groupedItems = purchasedItems.reduce((groups, item) => {
    const date = item.purchasedDate.toDate().toLocaleDateString();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});

  // 日付を新しい順にソート
  const sortedDates = Object.keys(groupedItems).sort((a, b) => new Date(b) - new Date(a));

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Checked</h2>
      {sortedDates.length > 0 ? (
        sortedDates.map((date) => (
          <div key={date} className="mb-4">
            <h3 className="text-lg font-bold mb-2">{date}</h3>
            <ul>
              {groupedItems[date].map((item) => (
                <li key={item.id} className="mb-2 bg-white p-2 rounded shadow">
                  <span className="line-through text-gray-500">{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
        ))
      ) : (
        <p className="text-gray-500">メモがありません</p>
      )}
    </div>
  );
};

export default PurchasedList;

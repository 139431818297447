// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBKQFFfvSqqGBNDk1YjNZlh4rYB6mYd20I",
  authDomain: "shopping-notes-app.firebaseapp.com",
  projectId: "shopping-notes-app",
  storageBucket: "shopping-notes-app.appspot.com",
  messagingSenderId: "912376149045",
  appId: "1:912376149045:web:131530b2405c768c5c6d7c"
};

// メモのデータモデル
const memo = {
  id: "memo1",
  text: "親メモ",
  parentId: null, // 親メモの場合はnull
  order: 0,
  createdAt: new Date(),
  purchased: false,
  purchasedDate: null
};

const childMemo = {
  id: "memo2",
  text: "子メモ",
  parentId: "memo1", // 親メモのID
  order: 1,
  createdAt: new Date(),
  purchased: false,
  purchasedDate: null
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { app, db, auth };
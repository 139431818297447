import React, { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, deleteDoc, doc, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig';
import { FaPlus, FaTimes, FaCheck } from 'react-icons/fa'; // FaTimes と FaCheck をインポート
import Modal from './Modal';

const ShoppingList = ({ items, setItems, purchasedItems, setPurchasedItems, input, handleInputChange, handleAddItem }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    if (!user) return;

    const q = query(collection(db, `users/${user.uid}/items`), orderBy('order'));
    const unsub = onSnapshot(q, (snapshot) => {
      const itemsData = [];
      snapshot.forEach((doc) => itemsData.push({ ...doc.data(), id: doc.id }));
      setItems(itemsData.filter((item) => !item.purchased));
      setPurchasedItems(itemsData.filter((item) => item.purchased));
    });

    return () => unsub();
  }, [user, setItems, setPurchasedItems]);

  useEffect(() => {
    if (!user) return;

    const q = query(collection(db, `users/${user.uid}/items`));
    const unsub = onSnapshot(q, (snapshot) => {
      const allItems = [];
      snapshot.forEach((doc) => allItems.push(doc.data().text));
      const uniqueItems = [...new Set(allItems)];
      const latestItems = uniqueItems.slice(-20); // 最新の20件を表示
      setSuggestions(latestItems);
    });

    return () => unsub();
  }, [user]);

  const handleSuggestionClick = async (suggestion) => {
    if (suggestion.trim()) {
      const newItem = {
        text: suggestion,
        purchased: false,
        createdAt: new Date(), // 選択した時点での日時を追加日として設定
        order: items.length > 0 ? items[0].order - 1 : 0, // 最上部に追加
      };
      await addDoc(collection(db, `users/${user.uid}/items`), newItem);
      setShowModal(false);
    }
  };

  const handleTogglePurchased = async (id) => {
    const item = items.find((item) => item.id === id) || purchasedItems.find((item) => item.id === id);
    if (item) {
      await updateDoc(doc(db, `users/${user.uid}/items`, id), {
        purchased: !item.purchased,
        purchasedDate: !item.purchased ? new Date() : null,
      });

      setTimeout(async () => {
        const updatedItem = items.find((item) => item.id === id) || purchasedItems.find((item) => item.id === id);
        if (updatedItem && updatedItem.purchased) {
          await handleDeleteItem(id);
        }
      }, 1000); // 1秒後に削除
    }
  };

  const handleDeleteItem = async (id) => {
    await deleteDoc(doc(db, `users/${user.uid}/items`, id));
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="p-4 pb-24"> {/* リスト外をタップすると編集モード終了 */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl">Memo</h2>
        <button
          onClick={toggleModal}
          className="bg-gray-500 text-white px-4 py-1 rounded flex items-center"
        >
          <FaPlus className="mr-2" /> 過去のメモ
        </button>
      </div>
      <ul className="space-y-2">
        {items.length > 0 ? (
          items.map((item, index) => (
            <li
              key={item.id}
              className={`flex justify-between items-center mb-2 bg-white p-2 rounded shadow ${item.purchased ? 'fade-out' : ''}`}
            >
              <button
                onClick={() => handleDeleteItem(item.id)}
                className="text-gray-500 p-1"
                aria-label="Delete"
                style={{ fontSize: '1rem' }} // ボタンを少し小さくする
              >
                <FaTimes />
              </button>
              <span className={`flex-grow ml-1 ${item.purchased ? 'line-through text-gray-500' : ''}`}>{item.text}</span>
              <button
                onClick={() => handleTogglePurchased(item.id)}
                className="ml-2 bg-green-500 text-white p-2 rounded h-full"
                aria-label="Mark as Purchased"
              >
                <FaCheck />
              </button>
            </li>
          ))
        ) : (
          <li className="text-gray-500">メモがありません</li>
        )}
      </ul>
      <Modal isOpen={showModal} onClose={toggleModal}>
        <ul>
          {suggestions.length > 0 ? (
            suggestions.map((suggestion, index) => (
              <li
                key={index}
                className="cursor-pointer p-1 hover:bg-blue-100"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </li>
            ))
          ) : (
            <li className="text-gray-500">メモがありません</li>
          )}
        </ul>
      </Modal>
    </div>
  );
};

export default ShoppingList;
